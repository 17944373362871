import * as utils from './utils'
import { JOBS_FILTERS_REDUCER_ACTIONS } from './jobsFiltersReducerActions'

const INITIAL_STATE = {
  jobs: [],
  filteredJobs: [],
  campuses: [],
  campusesSelectedCount: 0,
  departments: [],
  departmentsSelectedCount: 0,
  searchInputValue: '',
  revalidateSearch: false,
  resetKey: null,
}

export function jobsFiltersReducerInit({
  jobs,
  campuses,
  departments,
}) {
  const initialJobs = utils.removeNodeNesting(jobs)

  return {
    ...INITIAL_STATE,
    jobs: initialJobs,
    filteredJobs: initialJobs,
    campuses: utils.addIsSelectedFieldToNodes(campuses),
    departments: utils.addIsSelectedFieldToNodes(departments),
    resetKey: 1,
  }
}

export default function jobsFiltersReducer(state, action) {
  let newNodes, toggledNode

  switch (action.type) {
    case JOBS_FILTERS_REDUCER_ACTIONS.TOGGLE_CAMPUS:
      [newNodes, toggledNode] = utils.toggleNodeIsSelected(state.campuses, action.payload)

      if (state.searchInputValue) {
        return {
          ...state,
          campuses: newNodes,
          campusesSelectedCount: utils.updateSelectedNodesCount(state.campusesSelectedCount, toggledNode),
          revalidateSearch: true,
        }
      }
      return {
        ...state,
        filteredJobs: utils.applyFiltersToJobs(state.jobs, newNodes, state.departments),
        campuses: newNodes,
        campusesSelectedCount: utils.updateSelectedNodesCount(state.campusesSelectedCount, toggledNode),
        searchInputValue: '',
      }
    case JOBS_FILTERS_REDUCER_ACTIONS.TOGGLE_DEPARTMENT:
      [newNodes, toggledNode] = utils.toggleNodeIsSelected(state.departments, action.payload)

      if (state.searchInputValue) {
        return {
          ...state,
          departments: newNodes,
          departmentsSelectedCount: utils.updateSelectedNodesCount(state.departmentsSelectedCount, toggledNode),
          revalidateSearch: true,
        }
      }
      return {
        ...state,
        filteredJobs: utils.applyFiltersToJobs(state.jobs, newNodes, state.campuses),
        departments: newNodes,
        departmentsSelectedCount: utils.updateSelectedNodesCount(state.departmentsSelectedCount, toggledNode),
        searchInputValue: '',
      }
    case JOBS_FILTERS_REDUCER_ACTIONS.FILTER_SEARCHED_JOBS:
      if (action.payload === null) {
        // Initial render
        return {
          ...state,
          revalidateSearch: false,
        }
      } else if (action.payload.length === 0 && state.searchInputValue) {
        // No search results
        return {
          ...state,
          filteredJobs: [],
          revalidateSearch: false,
        }
      } else if (action.payload.length === 0 && !state.searchInputValue) {
        // Search input value was erased
        return {
          ...state,
          filteredJobs: utils.applyFiltersToJobs(state.jobs, state.departments, state.campuses),
          revalidateSearch: false,
        }
      }
      return {
        ...state,
        filteredJobs: utils.applyFiltersToSearchedJobs(action.payload, state.campuses, state.departments),
        revalidateSearch: false,
      }
    case JOBS_FILTERS_REDUCER_ACTIONS.UPDATE_SEARCH_INPUT_VALUE:
      if (action.payload === '') {
        return {
          ...state,
          filteredJobs: utils.applyFiltersToJobs(state.jobs, state.departments, state.campuses),
          searchInputValue: action.payload,
        }
      }
      return {
        ...state,
        searchInputValue: action.payload,
      }
    case JOBS_FILTERS_REDUCER_ACTIONS.RESET_FILTERS:
      return {
        ...INITIAL_STATE,
        jobs: state.jobs,
        filteredJobs: state.jobs,
        campuses: utils.deselectNodes(state.campuses),
        departments: utils.deselectNodes(state.departments),
        resetKey: state.resetKey + 1,
      }
    default:
      throw new Error('Invalid action provided')
  }
}
