import React from 'react'
import { useIntl } from 'react-intl'
import SelectField from './SelectField'
import { VARIANTS } from './SelectField/SelectField'

export default function CampsuesSelectField(props) {
  const intl = useIntl()

  return (
    <SelectField
      variant={VARIANTS.CAMPUS}
      label={intl.formatMessage({ id: 'location' })}
      {...props}
    />
  )
}
