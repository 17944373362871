export default function toggleNodeIsSelected(list, nodeId) {
  let toggledNode = null

  const newList = list.map((item) => {
    if (item.node.id !== nodeId) return item

    toggledNode = {
      ...item,
      isSelected: !item.isSelected,
    }

    return toggledNode
  })

  return [newList, toggledNode]
}
