import { useMemo, useRef } from 'react'

export const DEFAULT_SEARCH_CONFIG = {
  delay: 1000,
}

function buildSearchConfig(version, customSearchDelay) {
  return {
    ...DEFAULT_SEARCH_CONFIG,
    delay: customSearchDelay || DEFAULT_SEARCH_CONFIG.delay,
    version,
  }
}

export default function useSearchConfig(options) {
  const version = useRef(0)

  return useMemo(() => {
    const newVersion = version.current + 1
    const searchConfig = buildSearchConfig(newVersion, options.search_delay)
    version.current = newVersion
    return searchConfig
  }, [options.search_delay])
}
