import { useEffect, useReducer } from 'react'
import useJobsData from 'components/NewOpenRoles/hooks/useJobsData'
import { jobsFiltersReducerInit } from './jobsFiltersReducer/jobsFiltersReducer'
import { JOBS_FILTERS_REDUCER_ACTIONS } from './jobsFiltersReducer/jobsFiltersReducerActions'
import useJobsClientSearch from './hooks/useJobsClientSearch'
import jobsFiltersReducer from './jobsFiltersReducer'

export default function useJobsFiltersReducer(onChange, searchConfig) {
  const jobsData = useJobsData()
  const [state, dispatch] = useReducer(jobsFiltersReducer, jobsData, jobsFiltersReducerInit)
  const [searchedJobs, resetSearch] = useJobsClientSearch(state.jobs, state.searchInputValue, state.revalidateSearch, searchConfig)

  useEffect(() => {
    dispatch({
      type: JOBS_FILTERS_REDUCER_ACTIONS.FILTER_SEARCHED_JOBS,
      payload: searchedJobs,
    })
  }, [searchedJobs])

  useEffect(() => onChange(state.filteredJobs), [state.filteredJobs])

  function toggleCampus(id) {
    dispatch({
      type: JOBS_FILTERS_REDUCER_ACTIONS.TOGGLE_CAMPUS,
      payload: id,
    })
  }

  function toggleDepartment(id) {
    dispatch({
      type: JOBS_FILTERS_REDUCER_ACTIONS.TOGGLE_DEPARTMENT,
      payload: id,
    })
  }

  function updateSearchInputValue(newSearchInputValue) {
    dispatch({
      type: JOBS_FILTERS_REDUCER_ACTIONS.UPDATE_SEARCH_INPUT_VALUE,
      payload: newSearchInputValue,
    })
  }

  function resetFilters() {
    dispatch({
      type: JOBS_FILTERS_REDUCER_ACTIONS.RESET_FILTERS,
    })
    resetSearch()
  }

  const actions = {
    toggleCampus,
    toggleDepartment,
    updateSearchInputValue,
    resetFilters,
  }

  return [state, actions]
}
