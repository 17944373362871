import { useCallback, useEffect, useRef, useState } from 'react'
import * as JsSearch from 'js-search'
import { DEFAULT_SEARCH_CONFIG } from 'components/NewOpenRoles/hooks/useSearchConfig'

const JOB_UID_PATH = 'id'
const JOB_TITLE_PATH = 'name'

export default function useJobsClientSearch(jobs, searchInputValue, revalidate, config = DEFAULT_SEARCH_CONFIG) {
  const [searchedJobs, setSearchedJobs] = useState(null)
  const [delayInProgress, setDelayInProgress] = useState(false)
  const searchInputValueChangedDuringDelay = useRef(false)
  const prevDelayInProgressValue = useRef(false)
  const prevRevalidate = useRef(false)
  const jsSearchInstanceRef = useRef()

  // Instantiate Search class after first render
  useEffect(() => {
    const jsSearchInstance = new JsSearch.Search(JOB_UID_PATH)

    jsSearchInstance.indexStrategy = new JsSearch.ExactWordIndexStrategy()

    jsSearchInstance.addIndex(JOB_TITLE_PATH)
    if (jobs) {
      jsSearchInstance.addDocuments(jobs)
    }

    jsSearchInstanceRef.current = jsSearchInstance
  }, [])

  // Memoize search function
  const searchJobs = useCallback((inputText) => {
    if (typeof inputText !== 'string') {
      throw new Error('Invalid input text')
    }
    const newSearchedJobs = jsSearchInstanceRef.current.search(inputText)
    setSearchedJobs(newSearchedJobs)
  }, [])

  // Perform search if search input value has changed (either with or without the delay according to provided config)
  useEffect(() => {
    if (searchInputValue) {
      if (!config.delay) {
        searchJobs(searchInputValue)
      } else {
        if (!delayInProgress) {
          setTimeout(() => {
            searchJobs(searchInputValue)
            setDelayInProgress(false)
          }, config.delay)
          setDelayInProgress(true)
        } else {
          searchInputValueChangedDuringDelay.current = true
        }
      }
    }
  }, [searchInputValue])

  // Perform another search in case the input value has changed during the delay
  useEffect(() => {
    if (config.delay) {
      if (prevDelayInProgressValue.current) {
        if (searchInputValueChangedDuringDelay.current) {
          searchJobs(searchInputValue)
          searchInputValueChangedDuringDelay.current = false
        }
      }
      prevDelayInProgressValue.current = delayInProgress
    }
  }, [delayInProgress])

  // Trigger search process through revalidate flag
  useEffect(() => {
    if (revalidate && !prevRevalidate.current) {
      searchJobs(searchInputValue)
    }
    prevRevalidate.current = revalidate
  }, [revalidate])

  // Reset every local state apart from the Search class instance
  // This should be called if filters are reset in the UI
  function resetSearch() {
    setSearchedJobs(jobs)
    setDelayInProgress(false)
    searchInputValueChangedDuringDelay.current = false
    prevDelayInProgressValue.current = false
    prevRevalidate.current = false
  }

  return [searchedJobs, resetSearch]
}
