import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'components/Grid'
import { StyledContainer, JobsListItemTitle, StyledRow } from './styles'

export default function JobsListItem({
  name,
  departmentField,
  campus,
  lang,
  sm_key,
}) {
  const langPrefix = lang !== 'default' ? lang + '/' : ''
  const to = `/${langPrefix}careers/open-roles/${sm_key}`


  return (
    <StyledContainer>
      <StyledRow>
        <Link to={to}>
          <JobsListItemTitle>{name}</JobsListItemTitle>
        </Link>
      </StyledRow>
      <Row>
        <Col width={[1, 1 / 2, 1 / 3]}>
          <p>{departmentField}</p>
        </Col>
        <Col width={[1, 1 / 2, 2 / 3]}>
          <p>{campus}</p>
        </Col>
      </Row>
    </StyledContainer>
  )
}
