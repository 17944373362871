import _uniqBy from 'lodash/uniqBy'
import _intersectionBy from 'lodash/intersectionBy'

function removeEmpty(qualifiedJobs) {
  return qualifiedJobs.filter((qualifiedJobsList) => qualifiedJobsList.length > 0)
}

export default function applyFiltersToJobs(jobs, ...nodesLists) {
  const qualifiedJobs = []

  nodesLists.forEach((nodesList, nodesListIndex) => {
    qualifiedJobs[nodesListIndex] = []
    nodesList.forEach((item) => {
      const {
        isSelected,
        node,
      } = item

      const nodeJobs = []
      if (isSelected === true) {
        const campusName = node.label
        jobs.forEach((job) => {
          job.customField.forEach((campus) => {
            if (campusName === campus.valueLabel) {
              nodeJobs.push(job)
            }
          })
        })
      }

      if (nodeJobs.length) {
        qualifiedJobs[nodesListIndex] = [...qualifiedJobs[nodesListIndex], ...nodeJobs]
      }
    })
  })

  const clearedQualifiedJobs = removeEmpty(qualifiedJobs)

  if (clearedQualifiedJobs.length === 0) {
    return jobs
  } else if (clearedQualifiedJobs.length === 1) {
    return _uniqBy(clearedQualifiedJobs[0], 'id')
  }
  return _intersectionBy(...clearedQualifiedJobs.map(clearedQualifiedJobsList => _uniqBy(clearedQualifiedJobsList, 'id')), 'id')
}
