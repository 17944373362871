import React from 'react'
import { StyledInput } from './styles'

import searchIcon from 'static/img/search_icon_dark.svg'

export default function SearchField({ value, onChange }) {
  function handleChange(event) {
    onChange(event.target.value)
  }

  return (
    <StyledInput
      icon={searchIcon}
      type="text"
      aria-label="Search"
      value={value}
      onChange={handleChange}
    />
  )
}
