import React, { useContext } from 'react'
import {RemoveScroll} from 'react-remove-scroll';
import { navigate } from 'gatsby'
import find from 'lodash/find'

import { useLanguages } from 'hooks'
import ThemeContext from 'lib/theme.context'
import { cacheSelectedLang } from 'lib/lang-utils'

import * as Styled from './langSelector.styled'
import globe from 'static/img/globe.svg'
import globe_light from 'static/img/globe_light.svg'
import XIcon from 'static/img/x_icon_dark.svg'

function LangSelector({
  options,
  currentLang,
  location,
  isLangSelectorOpen,
  toggleLangSelector,
  className,
  isHovered,
  isSticked,
  whiteText
}) {
  const allLanguages = useLanguages()
  const { accentColor } = useContext(ThemeContext)

  function closeHandler() {
    toggleLangSelector()
  }

  async function handleSelect(value) {
    cacheSelectedLang(value)

    await navigateToLangPage(value)
  }

  async function navigateToLangPage(value) {
    if (location.pathname === '/404/') {
      return toggleLangSelector()
    }

    const langPathToGo = value === 'default' ? '' : value
    let slug

    if (currentLang === 'default') {
      slug = `/${langPathToGo}${location.pathname}`
    } else {
      slug = location.pathname.replace(
        `/${currentLang}`,
        langPathToGo ? `/${langPathToGo}` : '',
      )
    }

    await navigate(slug)
  }
  
  const disableScroll = () => {
    return (
      <RemoveScroll>
        <Styled.ListMobile isOpen={isLangSelectorOpen} accentColor={accentColor}>
        {options &&
          options
            .filter(a => {
              return a !== currentLang && find(allLanguages, { name: a })
            })
            .sort((a, b) => {
              return find(allLanguages, { name: a }).value.localeCompare(
                find(allLanguages, { name: b }, { value: '' }).value,
              )
            })
            .map(lang => (
              <MenuItem key={lang} value={lang} onSelect={handleSelect}>
                {find(allLanguages, { name: lang }).value}
              </MenuItem>
            ))}
          <Styled.XIcon
            icon={XIcon}
            onClick={closeHandler}
            isVisible={isLangSelectorOpen}
          />
      </Styled.ListMobile>
      </RemoveScroll>
    );
};

  return (
    <Styled.Wrapper className={className}>
      <Styled.LangList>  
        <li><div><img className="desktop-globe" 
          src={isSticked || isHovered ? globe 
            : whiteText ? globe_light
            : globe } 
          alt="Globe icon" 
        /></div>
          <ul class="dropdown">
          {options &&
            options
            .filter(a => {
              return a !== currentLang && find(allLanguages, { name: a })
            })
            .sort((a, b) => {
              return find(allLanguages, { name: a }).value.localeCompare(
                find(allLanguages, { name: b }, { value: '' }).value,
              )
            })
            .map(lang => (
              <MenuItem key={lang} value={lang} onSelect={handleSelect}>
                {find(allLanguages, { name: lang }).value}
              </MenuItem>
            ))}
          </ul>
        </li>
      </Styled.LangList>    
      
      <Styled.SelectDivMobile onClick={toggleLangSelector}>
        <img className="mobile-globe" src={globe} alt="Globe icon" />
      </Styled.SelectDivMobile>
      {isLangSelectorOpen ? disableScroll() : ''}
    </Styled.Wrapper>
  )
}

export default LangSelector

function MenuItem({ value, onSelect, children }) {
  function handleSelect() {
    onSelect(value)
  }
  return <li onClick={handleSelect}>{children}</li>
}
