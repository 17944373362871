function getSelectedNodes(nodes) {
  return nodes.filter(node => node.isSelected)
}

export default function applyFiltersToSearchedJobs(
  searchedJobs,
  campuses,
  departments,
) {
  const selectedCampuses = getSelectedNodes(campuses)
  const selectedDepartments = getSelectedNodes(departments)

  if (!selectedCampuses.length && !selectedDepartments.length) {
    return searchedJobs
  }

  return searchedJobs.filter(job => {
    const jobCampuses = job.customField.map(campus => {
      if (campus.fieldLabel === 'Campus') {
        return campus.valueLabel
      }
    })

    const commonCampuses = jobCampuses.filter(jobCampus => {
      return !!selectedCampuses.find(campus => campus.node.label === jobCampus)
    })

    if (!selectedDepartments.length) {
      return commonCampuses.length > 0
    }

    const jobDepartments = job.customField.map(jobFunction => {
      if (jobFunction.fieldLabel === 'Job Group') {
        return jobFunction.valueLabel
      }
    })

    const commonDepartments = jobDepartments.filter(jobDepartment => {
      return !!selectedDepartments.find(
        department => department.node.label === jobDepartment,
      )
    })

    if (!selectedCampuses.length) {
      return commonDepartments.length > 0
    }

    return commonCampuses.length > 0 && commonDepartments.length > 0
  })
}
