import React, { useContext } from 'react'
import Components from './components.js'
// import Components from './components.paths.js'
import SEO from './SEO'

import ThemeContext from 'lib/theme.context'

// import loadable from '@loadable/component'
// const LoadComponent = loadable(props => import(`../${props.path}`))

const Page = ({ blok, location, schemaLD, ...props }) => {
  const [seo] = blok.seo || [{}]

  const {
    pageTheme: { accentColor, primaryButtonStyle, secondaryButtonStyle },
    setPageTheme,
  } = useContext(ThemeContext)

  if (
    (blok.accent_color && blok.accent_color !== accentColor) ||
    (blok.primary_button_style &&
      blok.primary_button_style !== primaryButtonStyle) ||
    (blok.secondary_button_style &&
      blok.secondary_button_style !== secondaryButtonStyle)
  ) {
    setPageTheme({
      accentColor: blok.accent_color,
      primaryButtonStyle: blok.primary_button_style,
      secondaryButtonStyle: blok.secondary_button_style,
    })
  }

  return (
    <>
      <SEO
        lang={props.lang}
        pageTitle={seo.title}
        pageDescription={seo.description}
        pageImage={seo.image}
        schemaLD={schemaLD}
        location={location}
      />
      {blok.body &&
        blok.body.map(
          (blok, index) =>
            Components[blok.component] &&
            React.createElement(Components[blok.component], {
              key: blok._uid,
              blok,
              location,
              ...props,
              index,
            }),
        )}
    </>
  )
}
export default Page

/*
React.createElement(Components[blok.component], {
  key: blok._uid,
  blok,
  location,
  ...props,
}),
*/
