import { useContext } from 'react'
import ContentContext from 'lib/content.context'
import * as utils from './useJobsFiltersReducer/jobsFiltersReducer/utils'

export default function useJobsData() {
  const { allSmartJobs } = useContext(ContentContext)

  const initialJobs = utils.removeNodeNesting(allSmartJobs)

  const smartCampuses = []
  const smartFunctions = []

  initialJobs.map((job) => {
    job.customField.map((customFields) => {
      if (customFields.fieldLabel === 'Campus') {
        smartCampuses.push(customFields.valueLabel)
      }
      if (customFields.fieldLabel === 'Job Group') {
        smartFunctions.push(customFields.valueLabel)
      }
    })
  })

  const allSmartCampuses = smartCampuses.map(function(campus, index) {
    return {
      node: {
        id: index,
        label: campus,
      },
    }
  })

  const allSmartFunctions = smartFunctions.map(function(functions, index) {
    return {
      node: {
        id: index,
        label: functions,
      },
    }
  })

  return { departments: allSmartFunctions, campuses: allSmartCampuses, jobs: allSmartJobs }
}
