import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StyledButton, StyledParagraph } from './styles'

export default function FilteringStatusText({
  jobsCount,
  filteredJobsCount,
  campusesCount,
  campusesSelectedCount,
  departmentsCount,
  departmentsSelectedCount,
  resetFilters,
}) {
  const isFiltering = jobsCount !== filteredJobsCount || campusesSelectedCount === campusesCount || departmentsSelectedCount === departmentsCount

  function getFilteringStatusText() {
    switch (filteredJobsCount) {
      case 0:
        return <FormattedMessage id="filtering-filter-no-roles" />
      case 1:
        return <FormattedMessage id="filtering-filter-role" />
      default:
        return <FormattedMessage id="filtering-filter-many-roles" values={{ filteredJobsCount }} />
    }
  }

  function getStatusText() {
    switch (jobsCount) {
      case 0:
        return <FormattedMessage id="filtering-no-roles" />
      case 1:
        return <FormattedMessage id="filtering-role" />
      default:
        return <FormattedMessage id="filtering-many-roles" values={{ jobsCount }} />
    }
  }

  if (!isFiltering) {
    return (
      <StyledParagraph>
        {getStatusText()}
      </StyledParagraph>
    )
  }

  return (
    <StyledParagraph>
      {getFilteringStatusText()}
      <StyledButton onClick={resetFilters}>
        <FormattedMessage id="filtering-clear-all-filters" />
      </StyledButton>
    </StyledParagraph>
  )
}
